var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.$store.state.pricing.llenadoCostos)?_c('v-card',{staticClass:"px-5"},[_c('h1',[_vm._v(" OPCIÓN COSTOS "+_vm._s(_vm.$store.state.pricing.opcionCostos[_vm.$store.state.pricing.index] .nro_propuesta)+" ")]),_c('v-form',{ref:"frmDatosOpcion"},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"type":"date","name":("Fecha Validez de la Opción " + (_vm.$store.state.pricing.index + 1)),"label":("Fecha Validez de la Opción " + (_vm.$store.state.pricing.index + 1)),"rules":[
              function (v) { return !!v || 'Dato Requerido'; },
              function (v) { return _vm.isDateValid(v) || 'La fecha debe ser mayor que hoy.'; } ]},model:{value:(
              _vm.$store.state.pricing.opcionCostos[_vm.$store.state.pricing.index]
                .date_end
            ),callback:function ($$v) {_vm.$set(_vm.$store.state.pricing.opcionCostos[_vm.$store.state.pricing.index]
                , "date_end", $$v)},expression:"\n              $store.state.pricing.opcionCostos[$store.state.pricing.index]\n                .date_end\n            "}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-text-field',{attrs:{"type":"number","name":("Tiempo en tránsito de la Opción " + (_vm.$store.state.pricing.index + 1)),"label":("Tiempo en tránsito de la Opción " + (_vm.$store.state.pricing.index + 1)),"rules":[
              function (v) { return !!v || 'Dato Requerido'; },
              function (v) { return v > 0 || 'El valor tiene que ser positivo.'; } ]},model:{value:(
              _vm.$store.state.pricing.opcionCostos[_vm.$store.state.pricing.index]
                .tiempo_transito
            ),callback:function ($$v) {_vm.$set(_vm.$store.state.pricing.opcionCostos[_vm.$store.state.pricing.index]
                , "tiempo_transito", $$v)},expression:"\n              $store.state.pricing.opcionCostos[$store.state.pricing.index]\n                .tiempo_transito\n            "}})],1),_c('v-col',{attrs:{"cols":"8"}},[(_vm.btnIngresarCostos)?_c('v-btn',{staticClass:"mx-1 btn-ingresarcostos",attrs:{"color":"#3363A2","x-large":"","dark":""},on:{"click":function($event){return _vm.pasarLlenadoCostos()}}},[_vm._v(" INGRESAR COSTOS OPCIÓN "+_vm._s(_vm.$store.state.pricing.index + 1)+" ")]):_vm._e(),(_vm.$store.state.pricing.index > 0 && _vm.btnIngresarCostos)?_c('v-btn',{staticClass:"mx-1",attrs:{"color":"red","x-large":"","dark":""},on:{"click":function($event){return _vm.cancelarLlenadoCostos()}}},[_vm._v(" CANCELAR ")]):_vm._e()],1)],1)],1),(_vm.mostrarCostos)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('CostosComponent',{attrs:{"valores":_vm.$store.state.pricing.opcionCostos[
                _vm.$store.state.pricing.index
              ].listCostos.filter(function (v) { return v.esopcionflag == 1; }),"amount":_vm.$store.state.pricing.datosPrincipales.amount,"index":""}})],1),_c('v-col',{staticClass:"derecha",attrs:{"cols":"12"}},[_c('v-spacer'),(_vm.$store.state.pricing.mostrarGuardarCostos)?_c('v-btn',{attrs:{"color":"#3363A2","dark":""},on:{"click":function($event){return _vm.agregarNuevoCostoIrAVentas()}}},[_vm._v("GUARDAR COSTOS OPCIÓN "+_vm._s(_vm.$store.state.pricing.index + 1))]):_vm._e()],1)],1)],1):_vm._e()],1):_c('v-card',{staticClass:"px-5"},[_c('h1',[_vm._v(" OPCIÓN COSTOS "+_vm._s(_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1].nro_propuesta)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"type":"date","name":("Fecha Validez de la Opción " + (_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1].nro_propuesta)),"label":("Fecha Validez de la Opción " + (_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1].nro_propuesta)),"rules":[
            function (v) { return !!v || 'Dato Requerido'; },
            function (v) { return _vm.isDateValid(v) || 'La fecha debe ser mayor que hoy.'; } ]},model:{value:(
            _vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1].date_end
          ),callback:function ($$v) {_vm.$set(_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1], "date_end", $$v)},expression:"\n            opcionesSeleccionadas[$store.state.pricing.page - 1].date_end\n          "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"type":"number","name":("Tiempo en tránsito de la Opción " + (_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1].nro_propuesta)),"label":("Tiempo en tránsito de la Opción " + (_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1].nro_propuesta)),"rules":[
            function (v) { return !!v || 'Dato Requerido'; },
            function (v) { return v > 0 || 'El valor tiene que ser positivo.'; } ]},model:{value:(
            _vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1]
              .tiempo_transito
          ),callback:function ($$v) {_vm.$set(_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1]
              , "tiempo_transito", $$v)},expression:"\n            opcionesSeleccionadas[$store.state.pricing.page - 1]\n              .tiempo_transito\n          "}})],1),_c('v-col',{staticClass:"derecha",attrs:{"cols":"12"}},[[_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"length":_vm.opcionesSeleccionadas.length,"circle":""},model:{value:(_vm.$store.state.pricing.page),callback:function ($$v) {_vm.$set(_vm.$store.state.pricing, "page", $$v)},expression:"$store.state.pricing.page"}})],1)]],2),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6"}},[_c('CostosComponent',{attrs:{"valores":_vm.opcionesSeleccionadas[
                  _vm.$store.state.pricing.page - 1
                ].listCostos.filter(function (v) { return v.esopcionflag == 1; }),"actualizarCostosFlag":_vm.$store.state.pricing.actualizarCostosFlag,"amount":_vm.$store.state.pricing.datosPrincipales.amount,"index":_vm.$store.state.pricing.page}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6","xl":"6"}},[_c('VentasComponent',{attrs:{"valores":_vm.opcionesSeleccionadas[
                  _vm.$store.state.pricing.page - 1
                ].listCostos.filter(function (v) { return v.esventaflag == 1; }),"amount":_vm.$store.state.pricing.datosPrincipales.amount}})],1),_c('v-col',{staticClass:"derecha",attrs:{"cols":"12"}},[(_vm.mostrarContinuarVentas)?_c('v-btn',{attrs:{"color":"#3363A2","dark":""},on:{"click":_vm.activarImpuesto}},[_vm._v("Continuar")]):_vm._e()],1)],1)],1),(_vm.mostrarSeccionImpuestos)?_c('v-col',{attrs:{"cols":"6","offset":"6"}},[(_vm.mostrarImpuestos())?_c('ImpuestosComponent',{staticClass:"col-12",attrs:{"index":_vm.$store.state.pricing.opcionCostos[_vm.$store.state.pricing.index]
              .nro_propuesta,"amount":_vm.$store.state.pricing.datosPrincipales.amount,"impuestos":_vm.opcionesSeleccionadas[_vm.$store.state.pricing.page - 1].listImpuestos}}):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"izquierda",attrs:{"cols":"4"}},[(_vm.$store.state.pricing.opcionCostos.length > 1)?_c('v-btn',{attrs:{"color":"default"},on:{"click":function($event){return _vm.irAComparativa()}}},[_vm._v("Regresar a la comparativa")]):_vm._e()],1),(_vm.mostrarBotonIrANotas())?_c('v-col',{staticClass:"derecha"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.irANotas()}}},[_vm._v("IR A NOTAS DE COSTOS")])],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }